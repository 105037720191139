import styled from '@emotion/styled'
import { ModalClose } from 'app/components/Common/Header/ModalClose'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { Gallery } from 'app/components/GalleryCategories/Gallery'
import { FlexBox } from 'app/components/Layout/FlexBox'
import useLockedBody from 'app/utils/useLockedBody'
import { uniq } from 'lodash'
import React, { memo, useEffect, useState } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

export interface Props {
  images: ImageProps[]
  languageCode: string
  thumbnails?: ImageProps[]
}

export const Carousel = memo(function Carousel({
  images,
  languageCode,
  thumbnails,
}: Props) {
  if (images?.length < 1) {
    return null
  }

  const [_locked, setLocked] = useLockedBody()
  const [modalGalleryStatus, setModalGalleryStatus] = useState(false)
  const [clickedSlide, setClickedSlide] = useState<any>(null)

  useEffect(() => {
    if (modalGalleryStatus) {
      document.addEventListener('keydown', function (e) {
        switch (e.keyCode) {
          case 27:
            setLocked(false)
            setModalGalleryStatus(false)
            setClickedSlide(null)
            break
        }
      })
    }
  }, [modalGalleryStatus])

  return (
    <Container>
      {thumbnails ? (
        <Thumbs row wrap>
          {uniq(thumbnails)
            .slice(0, 4)
            .map((item, index) => (
              <Thumb
                key={index}
                onClick={() => {
                  if (!modalGalleryStatus) {
                    setLocked(true)
                    setModalGalleryStatus(true)
                  } else {
                    setLocked(false)
                    setModalGalleryStatus(false)
                  }

                  setClickedSlide(index)
                  setTimeout(() => {
                    setClickedSlide(null)
                  }, 100)
                }}
              >
                <LazyLoadComponent>
                  <Image {...item} />
                </LazyLoadComponent>
              </Thumb>
            ))}
        </Thumbs>
      ) : null}

      {images ? (
        <Modal className={modalGalleryStatus ? ' open' : undefined}>
          <ModalClose
            languageCode={languageCode}
            onClick={() => {
              setLocked(false)
              setModalGalleryStatus(false)
              setClickedSlide(null)
            }}
            variant="gallery"
          />
          <Gallery
            clickedSlide={clickedSlide}
            images={images}
            isOpen={modalGalleryStatus}
          />
        </Modal>
      ) : null}
    </Container>
  )
})

const Container = styled.div`
  margin-top: 5.25rem;

  @media (max-width: 1023px) {
    margin-top: 4.875rem;
  }
`

const Thumbs = styled(FlexBox)`
  margin-right: -1.5rem;

  @media (max-width: 767px) {
    margin-right: -0.125rem;
  }
`

const Thumb = styled.div`
  width: calc(25% - 1.5rem);
  background: ${({ theme }) => theme.colors.variants.neutralLight1};
  cursor: pointer;
  margin-right: 1.5rem;
  overflow: hidden;
  padding-bottom: calc(25% - 1.5rem);
  position: relative;
  &:hover {
    img {
      transform: scale(1.1);
    }
  }

  img {
    width: 101%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    transition: 0.3s ease-in-out !important;
  }

  @media (max-width: 767px) {
    width: calc(50% - 0.125rem);
    margin-top: 0.125rem;
    margin-right: 0.125rem;
    padding-bottom: calc(50% - 0.125rem);
  }
`

const Modal = styled.div`
  width: 100%;
  height: 100%;
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  visibility: hidden;
  z-index: -1;
  transition: 0.15s ease-out;
  &.open {
    opacity: 1;
    visibility: visible;
    z-index: 9999;
  }
`
