import styled from '@emotion/styled'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { Props as ImageProps } from 'app/components/Common/Image'
import { Rumble } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo } from 'react'

import { Carousel } from './Carousel'
import { Includes } from './Includes'
import { Legend } from './Legend'
import { Package, Props as PackageProps } from './Package'
import { Paragraph } from './Paragraph'
import { Validity } from './Validity'

export interface Props {
  beURL?: string
  galleryImages: ImageProps[]
  galleryThumbnails?: ImageProps[]
  includes?: string[]
  includesTitle?: string
  languageCode: string
  legend?: string[]
  packages?: PackageProps[]
  paragraphCTA?: ButtonProps
  paragraphTitle?: string
  quotationPageURL?: string
  validity?: string
  validityTitle?: string
}

export const OfferContent = memo(function OfferContent({
  beURL,
  galleryImages,
  galleryThumbnails,
  includes,
  includesTitle,
  languageCode,
  legend,
  packages,
  paragraphCTA,
  paragraphTitle,
  quotationPageURL,
  validity,
  validityTitle,
}: Props) {
  return (
    <Container>
      <Wrapper>
        {includes ? <Includes list={includes} title={includesTitle} /> : null}
        {legend ? <Legend list={legend} /> : null}

        <Validity description={validity} title={validityTitle} />

        {galleryImages ? (
          <Carousel
            images={galleryImages}
            languageCode={languageCode}
            thumbnails={galleryThumbnails}
          />
        ) : null}

        <Paragraph cta={paragraphCTA} title={paragraphTitle} />

        {packages ? (
          <Packages>
            {packages.map((item, index) => (
              <Package key={index} {...item} />
            ))}
          </Packages>
        ) : null}
      </Wrapper>

      {packages && packages?.length < 1 ? (
        <Banner>
          <Rumble />
          <Title>
            {useVocabularyData('offer-buttons-title', languageCode)}
          </Title>
          {beURL || quotationPageURL ? (
            <Buttons dial={5} row>
              {beURL ? (
                <Button
                  label={useVocabularyData('book', languageCode)}
                  target="_blank"
                  URL={beURL}
                />
              ) : null}
              {quotationPageURL ? (
                <Button
                  label={useVocabularyData('request', languageCode)}
                  target="_blank"
                  URL={quotationPageURL}
                  variant="primary"
                />
              ) : null}
            </Buttons>
          ) : null}
        </Banner>
      ) : null}
    </Container>
  )
})

const Container = styled.section`
  background: ${({ theme }) => theme.colors.variants.neutralLight2};
`

const Wrapper = styled.div`
  padding: 4rem 19.722vw 11.5625rem;

  @media (max-width: 1199px) {
    padding-right: 2rem;
    padding-left: 2rem;
  }

  @media (max-width: 1023px) {
    padding-top: 3.1875rem;
    padding-bottom: 8.4375rem;
  }
`

const Banner = styled.div`
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  padding: 6.0625rem 1.875rem 7.75rem;
  position: relative;
  text-align: center;

  > svg {
    width: 2.5rem;
    height: auto;
    fill: ${({ theme }) => theme.colors.variants.secondaryLight};
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  @media (max-width: 1023px) {
    padding-right: 2rem;
    padding-left: 2rem;
  }

  @media (max-width: 767px) {
    padding-top: 3.125rem;
    padding-bottom: 4.25rem;
  }
`

const Title = styled.div`
  max-width: 32.5rem;
  color: ${({ theme }) => theme.colors.variants.secondaryLight};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.5rem;
  letter-spacing: 0.125rem;
  line-height: 3.125rem;
  margin: 0 auto 1.75rem;
  text-transform: uppercase;

  @media (max-width: 1023px) {
    font-size: 1.5rem;
    line-height: 1.75rem;
  }
`

const Buttons = styled(FlexBox)`
  a {
    min-width: 12.5rem;
    margin: 0 0.375rem;
  }

  @media (max-width: 767px) {
    display: block;
    margin-top: 2rem;

    a {
      width: 100%;
      &:nth-of-type(2) {
        margin: 0.625rem 0 0;
      }
    }
  }
`

const Packages = styled.div`
  margin-top: 3.75rem;

  @media (max-width: 767px) {
    margin-top: 5rem;
  }
`
